<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="8">
        <b-card footer-tag="footer">
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <b-row>
              <b-col md="3">
                <button class="btn btn-primary" @click="add">Нэмэх</button>
              </b-col>
            </b-row>
          </div>
          <div class="mb-3">
            <div class="table-responsive">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr class="text-center">
                    <th>#</th>
                    <th>Нэр</th>
                    <th>Зураг</th>
                    <th>эрэмбэ</th>
                    <th>линк</th>
                    <th>үйлдэл</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(banner, index) in banner_list" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>{{ banner["name"] }}</td>
                    <td class="text-center">
                      <img
                        :src="banner['image_link']"
                        width="100"
                        class="img-thumbnail"
                      />
                    </td>
                    <td class="text-center">{{ banner["sort_order"] }}</td>
                    <td class="text-center">
                      <a :href="banner['url']" v-if="banner['url']">линк</a>
                    </td>
                    <td>
                      <button
                        href=""
                        class="btn btn-primary btn-sm"
                        @click="selectBanner(banner)"
                      >
                        Засах
                      </button>
                      <button
                        href=""
                        class="btn btn-danger btn-sm ml-1"
                        @click="deleteBanner(banner.id)"
                      >
                        Устгах
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card footer-tag="footer">
          <loading
            :active.sync="isLoadingForm"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <h4 v-if="selected_banner['id']">
            Засах
          </h4>
          <h4 v-else>
            Шинээр нэмэх
          </h4>
          <hr />
          <form @submit="saveOperator">
            <div class="form-group">
              <label for="">Нэр</label>
              <input type="text" class="form-control" v-model="form.name" />
            </div>
            <div class="form-group">
              <label for="">Зураг</label>
              <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <img
                v-if="file_link"
                :src="file_link"
                class="img-thumbnail"
                width="100"
              />
            </div>
            <div class="form-group">
              <label for="">Эрэмбэ</label>
              <input
                type="number"
                class="form-control"
                v-model="form.sort_order"
              />
            </div>
            <div class="form-group">
              <label for="">Линк</label>
              <input type="url" class="form-control" v-model="form.url" />
            </div>

            <button type="submit" class="btn btn-primary text-center">
              Хадгалах
            </button>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Menu",
  components: { axios },
  data: function() {
    return {
      is_active: true,
      // banner_domain: "http://localhost:5010",
      banner_domain: "https://banner.numur.mn",
      isLoadingForm: false,
      isLoading: false,
      selected_banner: {
        id: null,
        name: null,
        sort_order: null,
        is_active: true,
        url: null
      },
      form: {
        id: null,
        name: null,
        sort_order: null,
        is_active: true,
        url: null
      },
      banner_list: [],

      file: null,
      file_link: null
    };
  },
  created() {
    this.load_data();
  },
  watch: {
    is_active: {
      handler(is_active) {
        if (is_active) {
          this.$data.form.is_active = 1;
        } else {
          this.$data.form.is_active = 0;
        }
      },
      deep: true
    }
  },
  methods: {
    add: function() {
      this.$data.form = {
        id: null,
        name: null,
        sort_order: null,
        is_active: true,
        url: null
      };
    },
    selectBanner: function(_op) {
      this.$data.selected_banner = _op;
      this.$data.file = null;
      this.$data.file_link = _op["image_link"];
      this.$data.form.name = _op["name"];
      this.$data.form.sort_order = _op["sort_order"];
      this.$data.form.url = _op["url"];
    },
    saveOperator: function() {
      if (this.$data.selected_banner.id) {
        this.updateOperator();
      } else {
        this.createOperator();
      }
    },
    createOperator: function() {
      this.isLoadingForm = true;
      let formData = new FormData();
      formData.append("image", this.$data.file);
      formData.append("name", this.$data.form.name);
      formData.append("sort_order", this.$data.form.sort_order);
      formData.append("url", this.$data.form.url);
      let _this = this;
      axios
        .post(this.$data.banner_domain + "/create_banner", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function(response) {
          console.log(response);
          if (response.data.success) {
            _this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
            _this.load_data();
          } else {
            _this.showToast("Анхаар", response.data.message, "warning");
          }
          _this.isLoadingForm = false;
        })
        .catch(function(response) {
          _this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          _this.isLoadingForm = false;
        });
    },
    updateOperator: function() {
      this.isLoadingForm = true;
      let formData = new FormData();
      formData.append("image", this.$data.file);
      formData.append("name", this.$data.form.name);
      formData.append("sort_order", this.$data.form.sort_order);
      formData.append("url", this.$data.form.url);
      let _this = this;
      axios
        .post(
          this.$data.banner_domain +
            "/update_banner/" +
            this.$data.selected_banner.id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(function(response) {
          console.log(response);
          if (response.data.success) {
            _this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
            _this.load_data();
          } else {
            _this.showToast("Анхаар", response.data.message, "warning");
          }
          _this.isLoadingForm = false;
        })
        .catch(function(response) {
          _this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          _this.isLoadingForm = false;
        });
    },
    deleteBanner: function(_id) {
      var result = confirm("Устгахдаа итгэлэй байна уу?");
      if (result) {
        this.isLoading = true;
        this.$http
          .get(this.$data.banner_domain + "/delete_banner/" + _id, {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true
          })
          .then(
            (response) => {
              this.isLoading = false;
              var result = response.data.success;
              if (result) {
                this.showToast("Амжилттай", "Амжилттай устгалаа", "success");
                this.load_data();
              } else {
                this.showToast("Анхаар", "Устгахад алдаа гараа", "warning");
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
      }
    },
    load_data: function() {
      this.isLoading = true;

      this.$http
        .get(this.$data.banner_domain + "/get_banner_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.banner_list = response.body;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    }
  }
};
</script>
